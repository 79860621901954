var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("van-field", {
          attrs: {
            placeholder: "手机号码",
            "left-icon": "phone-o",
            "error-message": _vm.phoneError
          },
          model: {
            value: _vm.phone,
            callback: function($$v) {
              _vm.phone = $$v
            },
            expression: "phone"
          }
        }),
        _vm._v(" "),
        _vm.type === "password"
          ? _c(
              "van-field",
              {
                attrs: {
                  placeholder: "登录密码",
                  "left-icon": "lock",
                  type: _vm.passwordType
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              },
              [
                _c("van-icon", {
                  attrs: { slot: "right-icon", name: _vm.passwordIcon },
                  on: { click: _vm.switchPasswordType },
                  slot: "right-icon"
                })
              ],
              1
            )
          : _c(
              "van-field",
              {
                attrs: {
                  placeholder: "短信验证码",
                  "left-icon": "comment-o",
                  type: "text"
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              },
              [
                _c("VerifyCodeBtn", {
                  attrs: { slot: "button", type: "login" },
                  on: { sendVerifyCode: _vm.sendVerifyCode },
                  slot: "button",
                  model: {
                    value: _vm.phone,
                    callback: function($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone"
                  }
                })
              ],
              1
            ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "button-wrap" },
          [
            _c(
              "van-button",
              {
                attrs: { size: "large", type: "info", disabled: _vm.disabled },
                on: { click: _vm.login }
              },
              [_vm._v("登录")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "more-wrap" }, [
          _c(
            "div",
            { staticClass: "switch-way", on: { click: _vm.switchType } },
            [_vm._v(_vm._s(_vm.loginWayObj.toggleMsg))]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: {
            src: require("../../images/login/getheadimg.jpeg"),
            alt: "default_logo"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }