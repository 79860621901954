var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-top": "50%" } },
    [
      _c(
        "van-cell-group",
        [
          _c("van-field", {
            attrs: {
              readonly: "",
              clickable: "",
              name: "picker",
              value: _vm.value,
              label: "选择公司",
              placeholder: "点击选择保险公司名称"
            },
            on: {
              click: function($event) {
                _vm.showPicker = true
              }
            }
          }),
          _vm._v(" "),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showPicker,
                callback: function($$v) {
                  _vm.showPicker = $$v
                },
                expression: "showPicker"
              }
            },
            [
              _c("van-picker", {
                attrs: { "show-toolbar": "", columns: _vm.columns },
                on: {
                  confirm: _vm.onConfirm,
                  cancel: function($event) {
                    _vm.showPicker = false
                  },
                  change: _vm.change
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }