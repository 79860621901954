var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      [
        _c("van-nav-bar", {
          attrs: {
            title: "新增地址",
            "left-text": "返回",
            "left-arrow": "",
            "z-index": 10
          },
          on: { "click-left": _vm.goBack }
        }),
        _vm._v(" "),
        _c("van-address-edit", {
          attrs: {
            "area-list": _vm.areaList,
            "show-postal": "",
            "show-set-default": "",
            "show-search-result": ""
          },
          on: { save: _vm.onSave, "change-detail": _vm.onChangeDetail }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }