"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _VerifyCodeBtn = _interopRequireDefault(require("@/components/VerifyCodeBtn"));

var _index = require("@/api/user/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Modpwd',
  components: {
    VerifyCodeBtn: _VerifyCodeBtn.default
  },
  watch: {
    phone: function phone(newVal) {
      this.checkDisable();
    },
    captcha: function captcha(newVal) {
      this.checkDisable();
    }
  },
  data: function data() {
    return {
      phone: '',
      user: '',
      captcha: '',
      disabled: true
    };
  },
  methods: {
    checkDisable: function checkDisable() {
      if (this.phone.length === 11 && this.captcha.length === 4 || this.phone === this.user.phone) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    cofirm: function cofirm() {
      var _this = this;

      if (this.phone === this.user.phone) {
        this.$toast.fail('换绑手机号相同');
      }

      var phone = this.phone,
          captcha = this.captcha;
      var that = this;
      (0, _index.changePhone)({
        phone: phone,
        captcha: captcha
      }).then(function (res) {
        if (res.code === 0) {
          _this.$toast.success({
            'message': '换绑成功',
            onClose: function onClose() {
              that.$store.dispatch('user/getInfo').then(function () {
                that.$router.push({
                  name: 'secure'
                });
              });
            }
          });
        }
      });
    }
  },
  created: function created() {
    this.user = this.userInfo();
  }
};
exports.default = _default;