var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "van-cell-group",
          [
            _c("van-field", {
              attrs: {
                label: "手机号",
                placeholder: "请输入新手机号",
                type: "number"
              },
              model: {
                value: _vm.phone,
                callback: function($$v) {
                  _vm.phone = $$v
                },
                expression: "phone"
              }
            }),
            _vm._v(" "),
            _c("van-field", {
              attrs: {
                center: "",
                clearable: "",
                label: "短信验证码",
                placeholder: "请输入4位验证码",
                type: "number"
              },
              scopedSlots: _vm._u([
                {
                  key: "button",
                  fn: function() {
                    return [
                      _c("Verify-code-btn", {
                        attrs: {
                          slot: "button",
                          type: "newPhone",
                          "btn-msg": "获取验证码"
                        },
                        slot: "button",
                        model: {
                          value: _vm.phone,
                          callback: function($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.captcha,
                callback: function($$v) {
                  _vm.captcha = $$v
                },
                expression: "captcha"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "van-button",
              {
                staticClass: "btnSubmit",
                attrs: {
                  type: "primary",
                  disabled: _vm.disabled,
                  size: "large"
                },
                on: { click: _vm.cofirm }
              },
              [_vm._v("确定")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "mobile-icon" }),
      _vm._v(" "),
      _c("p", { staticClass: "title" }, [_vm._v("验证新手机号")]),
      _vm._v(" "),
      _c("p", { staticClass: "description" }, [
        _vm._v("新手机号作为后期登录账号")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }