var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "main" },
        _vm._l(_vm.numPages, function(item) {
          return _c("pdf", {
            key: item,
            attrs: { src: _vm.pdfUrl, page: item },
            on: { loaded: _vm.hideLoading }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("Loading", {
        ref: "Loading",
        attrs: { title: "正在努力加载产品费率..." }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }