"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    value: {}
  },
  watch: {
    value: {
      handler: function handler(newVal) {
        this.result = newVal;
      },
      immediate: true
    },
    dialogVisable: {
      handler: function handler(newVal) {
        if (newVal === true) {
          this.form[this.$attrs.field] = this.value;
        }
      }
    }
  },
  data: function data() {
    return {
      result: this.value,
      dialogVisable: false,
      dialogTitle: "\u4FEE\u6539".concat(this.$attrs.label),
      form: (0, _defineProperty2.default)({}, this.$attrs.field, this.value)
    };
  },
  methods: {
    confirm: function confirm(action, done) {
      if (action === 'cancel') {
        // 取消按钮
        done();
      } else if (action === 'confirm') {
        // 确定按钮
        var field = this.$attrs.field;

        if (this.$attrs['required'] !== false && !this.form[field]) {
          this.$toast(this.$attrs['label'] + '不可为空');
          done(false);
          return false;
        }

        this.$emit('save', this.form);
        done();
      }

      return false;
    }
  }
};
exports.default = _default;