var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-cell-group",
        {},
        [
          _c("van-nav-bar", {
            attrs: {
              title: "账户安全",
              "left-text": "返回",
              "left-arrow": "",
              "z-index": 10
            },
            on: {
              "click-left": function($event) {
                return _vm.$router.push("/my")
              }
            }
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "绑定手机",
              icon: "phone",
              value: _vm.mobileStar(_vm.user.phone),
              "is-link": ""
            },
            on: {
              click: function($event) {
                return _vm.go("modphone")
              }
            }
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "登录密码",
              icon: "lock",
              "is-link": "",
              value: "修改密码"
            },
            on: {
              click: function($event) {
                return _vm.go("modpwd")
              }
            }
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              title: "关联账号",
              icon: "friends-o",
              "is-link": "",
              value: "微信等"
            },
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "bindIndex" })
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }