"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/image-preview/style");

var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));

var _FooterTabbar = _interopRequireDefault(require("components/FooterTabbar"));

var _index = require("@/api/user/index");

var _default = {
  name: 'User',
  components: {
    FooterTabbar: _FooterTabbar.default,
    ImagePreview: _imagePreview.default
  },
  data: function data() {
    return {
      user: '',
      stats: '',
      // 头像
      icon: {
        login_icon: require("./../../images/mine/defaultImg.jpeg"),
        noLogin_icon: require("./../../images/login/default_avatar.jpg")
      }
    };
  },
  methods: {
    goToPage: function goToPage(type) {
      switch (type) {
        case 'login':
          this.$router.push(this.getLoginUrl(this.$route.path));
          break;

        default:
          this.$router.push({
            path: type
          });
      }
    },
    goTomyOrder: function goTomyOrder() {
      this.$toast('goToMyOrder');
    },
    priview: function priview() {
      if (this.user) {
        (0, _imagePreview.default)({
          images: [this.user.avatar],
          showIndex: false,
          onClose: function onClose() {}
        });
      }
    }
  },
  created: function created() {
    var _this = this;

    this.user = this.userInfo();

    if (this.user) {
      (0, _index.stats)().then(function (res) {
        if (!res.code) _this.stats = res.data;
      });
    }
  }
};
exports.default = _default;